import { BrowserModule, TransferState } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { ReactiveFormsModule, FormsModule, NgForm } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {LocationStrategy, Location, PathLocationStrategy} from '@angular/common';

import { NgxLoadingModule } from 'ngx-loading';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbsModule } from '@exalif/ngx-breadcrumbs';
import { NgxPaginationModule } from 'ngx-pagination';
import { ParallaxModule, ParallaxConfig } from 'ngx-parallax';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { FilterPipeModule } from 'ngx-filter-pipe';

import { AppComponent } from './app.component';
import { SliderComponent } from './shared/slider/slider.component';
import { FooterComponent } from './shared/footer/footer.component';

import { DataService } from './services/data.service';
import { LastNewsComponent } from './components/last-news/last-news.component';
import { CotelComponent } from './pages/cotel/cotel.component';
import { CotelcoComponent } from './pages/cotelco/cotelco.component';

import { AppRoutingModule } from './app-routing.module';
import { AfiliadosComponent } from './pages/afiliados/afiliados.component';
import { CapacitacionesComponent } from './pages/investigation/capacitaciones/capacitaciones.component';
import { BillboardComponent } from './components/billboard/billboard.component';
import { NgxGalleryModule } from 'ngx-gallery';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { FileSaverModule } from 'ngx-filesaver';
import { OrgChartModule } from 'ng2-org-chart';
import { ToastrModule } from 'ngx-toastr';

/**
 * Modals Components
 */


import { ModalAboutProfileComponent } from './components/modals/about-profile/about-profile.component';
import { ModalVideoComponent } from './components/modals/video/video.component';
import { ModalAuthComponent } from './components/modals/auth/auth.component';
import { ModalGalleryComponent } from './components/modals/gallery/gallery.component';
import { PaymentComponent } from './components/modals/payment/payment.component';
import { PaymentModalComponent } from './components/modals/payment-modal/payment-modal.component'
import { PaymentInformationModalComponent } from './components/modals/payment-information-modal/payment-information-modal.component';
import { ContactSuccessComponent } from './components/modals/contact-success/contact-success.component';

/**
 * New header
 */


import { HeaderNewComponent } from './shared/header-new/header-new.component';


/**
 * Spreaker component
 */


import { SpeakerComponent } from './components/speaker/speaker.component';


/**
 * Events Components
 */

import { EventComponent } from './pages/displays/event/event.component';
import { EventFutureComponent } from './components/event-future/event-future.component';
import { EventDetailsComponent } from './pages/displays/events/event-details/event-details.component';

/**
 * Affiliate Components
 */

import { AffiliateComponent } from './pages/affiliate/affiliate.component';
import { RegisterComponent } from './pages/affiliate/register/register.component';

import { BenefitsComponent } from './pages/affiliate/benefits/benefits.component';

/**
 * aliados
 */

import { AliatesComponent } from './pages/info/aliates/aliates.component';

/**
 * Consultansy
 */

import { ConsultancyComponent } from './pages/investigation/consultancy/consultancy.component';
import { ConsultancyDetailComponent } from './pages/investigation/consultancy/detail/detail.component';

/**
 * Contact
 *
 */


import { ContactComponent } from './pages/contact/contact.component';
import { ContactWidgetComponent } from './components/contact/contact.component';

/**
 * new home
 */

import { NewHomeComponent } from './pages/new-home/new-home.component';
import { StatisticsComponent } from './components/statistics/statistics.component';

 /**
  * Tainings
  */

import { TrainingsComponent } from './pages/investigation/trainings/trainings.component';
import { LastTrainingsComponent } from './pages/investigation/trainings/last-trainings/last-trainings.component';
import { TrainingsCalendarComponent } from './pages/investigation/trainings/calendar/calendar.component';
import { TrainingsDetailsComponent } from './pages/investigation/trainings/details/details.component';

 /**
  * Notices
  */

import { NoticesComponent } from './pages/notices/notices.component';
import { NoticePublicationsComponent } from './pages/notices/publications/publications.component';


 /**
 * About
 */
import { AboutComponent } from './pages/info/about/about.component';

 /**
 * Directors
 */
import {DirectorsComponent} from './pages/info/directors/directors.component';

 /**
 * Functionaries
 */
import {FunctionariesComponent} from './pages/info/functionaries/functionaries.component';

/**
 * Chapters
 */

import { ChaptersComponent } from './pages/info/chapters/chapters.component';

/**
 * Library
 */

import { LibraryComponent } from './pages/notices/library/library.component';

/**
 * Validator
 */

import { ValidatorComponent } from './pages/validator/validator.component';

/**
 * Results
 */

import { ResultsComponent } from './pages/results/results.component';


// Services

import { EventsService } from './services/events.service';
import { AffiliatesService } from './services/affiliates/affiliates.service';
import { SliderService } from './services/slider/slider.service';
import { AliateService } from './services/aliate/aliate.service';
import { ConsultancyService } from './services/consultancy/consultancy.service';
import { TrainingsService } from './services/trainings/trainings.service';
import { NewService } from './services/news/new.service';
import { ChapterService } from './services/chapters/chapter.service';
import { StatisticService } from './services/statistics/statistic.service';
import { PublicationsService } from './services/notices/publications/publications.service';
import { LibraryService } from './services/library/library.service';
import { AboutService } from './services/about/about.service';
import { AuthService } from './services/auth/auth.service';
import { ValidatorService } from './services/validator/validator.service';
import { MomentService } from './services/moment/moment.service';
/**
 * insurance
 */

import { InsuranceComponent } from './pages/investigation/insurance/insurance.component';

/** notifications */

import { NotificationService } from './services/notification/notification.service';


/**
 * Carousel
 */

import { CarouselModule } from 'ngx-owl-carousel-o';
import { OwlCarouselComponent } from './components/owl-carousel/owl-carousel.component';

/**
 * Read More
 */
import { ReadMoreComponent } from './components/read-more/read-more.component';


/**
 * testimonials
 */

import { TestimonialsComponent } from './components/testimonials/testimonials.component';

/**
 * Memorias
 */

import { MemoriesComponent } from './pages/memories/memories.component';

/**
 * Profile official
 */
import { ProfileOfficialComponent } from './pages/info/profile-official/profile-official.component';

/**
 * data-policy
 */

import {  DataPolicyComponent} from './pages/data-policy/data-policy.component';

import { TutorsVerticalComponent } from './components/tutors/tutors.component';
import { ResearchersComponent } from './components/researchers/researchers.component';
import { NoticesDetailComponent } from './pages/notices-detail/notices-detail.component';
import { ViewfinderComponent } from './shared/viewfinder/viewfinder.component';
import { StatisticsPageComponent } from './pages/investigation/statistics-page/statistics-page.component';

import { StatisticsDetailComponent } from './pages/statistics-detail/statistics-detail.component';
import { SocialButtonsComponent } from './components/social-buttons/social-buttons.component';
import { FeriaDetailComponent } from './pages/displays/feria/detail/feria.component';
import { NoEventComponent } from './components/modals/no-event/no-event.component';
import { FairsComponent } from './pages/displays/feria/feria.component';
import { EventCalendarComponent } from './pages/displays/events/calendar/calendar.component';
import { WorkshopCalendarComponent } from './pages/displays/feria/calendar/calendar.component';
import { BusinessComponent } from './pages/displays/business/business.component';
import { BusinessDetailComponent } from './pages/displays/business/detail/business-detail.component';
import { BusinessCalendarComponent } from './pages/displays/business/calendar/calendar.component';
import { ProjectComponent } from './pages/investigation/projects/project.component';
import { ProjectDetailsComponent } from './pages/investigation/projects/details/details.component';
import { LastProjectsComponent } from './pages/investigation/projects/last-trainings/last-projects.component';
import { InscriptionModalComponent } from './components/modals/inscription-modal/inscription-modal.component';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-CO';
import localeEsExtra from '@angular/common/locales/extra/es-CO';
import { SafeHtmlPipe } from './pipes/safehtml.pipe';
import { MetaModule, MetaLoader, MetaStaticLoader, PageTitlePositioning } from '@ngx-meta/core';
import { TransferHttpModule, TransferHttpService } from '@gorniv/ngx-universal';
import { FixedBarComponent } from './components/modals/fixed-bar/fixed-bar.component';
import { LocaleUsComponent } from './pages/contacts/locale-us/locale-us.component';
import { PqrsComponent } from './pages/contacts/pqrs/pqrs.component';
import { FaqsComponent } from './pages/contacts/faqs/faqs.component';

import { CommonModule } from '@angular/common';



// registerLocaleData(localeEs, 'es', localeEsExtra);
// export function metaFactory(): MetaLoader {
//   return new MetaStaticLoader({
//     pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
//     pageTitleSeparator: ' - ',
//     applicationName: 'Cotelco',
//     defaults: {
//       title: 'Cotelco',
//       description: 'Cotelco',
//       'og:image': 'https://upload.wikimedia.org/wikipedia/commons/f/f8/superraton.jpg',
//       'og:type': 'website',
//       'og:locale': 'es_ES',
//     }
//   });
// }

/**
 * 
 * 
    BrowserAnimationsModule,
       FormsModule,
    ReactiveFormsModule,
    CarouselModule,
    NgbModule.forRoot(),
    NgxPaginationModule,
    NgxGalleryModule,
    RecaptchaModule,
    RecaptchaFormsModule,
 * 
 */

@NgModule({
  declarations: [
    AppComponent
    //SafeHtmlPipe
    //BillboardComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    
    BrowserModule.withServerTransition({ 
      appId: 'ng-cli-universal' 
    }),
    BrowserAnimationsModule,
    //BreadcrumbsModule,
    //NoopAnimationsModule,
    RouterModule,
    HttpModule,
    HttpClientModule,
    // RecaptchaModule,
    // RecaptchaFormsModule,
    
    FileSaverModule,
    FilterPipeModule,
    OrgChartModule,
    NgxLoadingModule.forRoot({}),
    NgxYoutubePlayerModule.forRoot(),
    ToastrModule.forRoot(), // ToastrModule added
    TransferHttpModule
  ],
  providers: [
 

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
