import { NgModule, Component } from '@angular/core';
import { ExtraOptions, Routes, RouterModule } from '@angular/router';
import { BreadcrumbsModule } from '@exalif/ngx-breadcrumbs';
import { CotelcoComponent } from './pages/cotelco/cotelco.component';
import {DirectorsComponent} from './pages/info/directors/directors.component';
import {FunctionariesComponent} from './pages/info/functionaries/functionaries.component';
import { ChaptersComponent } from './pages/info/chapters/chapters.component';
import { CapacitacionesComponent } from './pages/investigation/capacitaciones/capacitaciones.component';
import { EventsComponent } from './pages/displays/events/events.component';
import { EventDetailsComponent } from './pages/displays/events/event-details/event-details.component';
import { AffiliateComponent } from './pages/affiliate/affiliate.component';
import { RegisterComponent } from './pages/affiliate/register/register.component';
import { BenefitsComponent } from './pages/affiliate/benefits/benefits.component';
import { AliatesComponent } from './pages/info/aliates/aliates.component';
import { ConsultancyComponent } from './pages/investigation/consultancy/consultancy.component';
import { ContactComponent } from './pages/contact/contact.component';
import { NewHomeComponent } from './pages/new-home/new-home.component';
import { TrainingsComponent } from './pages/investigation/trainings/trainings.component';
import { NoticesComponent } from './pages/notices/notices.component';
import { AboutComponent } from './pages/info/about/about.component';
import { ConsultancyDetailComponent } from './pages/investigation/consultancy/detail/detail.component';
import { NoticePublicationsComponent } from './pages/notices/publications/publications.component';
import { LibraryComponent } from './pages/notices/library/library.component';
import { ValidatorComponent } from './pages/validator/validator.component';
import { MemoriesComponent } from './pages/memories/memories.component';
import { TrainingsCalendarComponent } from './pages/investigation/trainings/calendar/calendar.component';
import { TrainingsDetailsComponent } from './pages/investigation/trainings/details/details.component';
import { NoticesDetailComponent } from './pages/notices-detail/notices-detail.component';
import { ResultsComponent } from './pages/results/results.component';
import { StatisticsPageComponent } from './pages/investigation/statistics-page/statistics-page.component';
import { StatisticsDetailComponent } from './pages/statistics-detail/statistics-detail.component';
import{ DataPolicyComponent } from './pages/data-policy/data-policy.component';
import { FeriaDetailComponent } from './pages/displays/feria/detail/feria.component';
import { FairsComponent } from './pages/displays/feria/feria.component';
import { InsuranceComponent } from './pages/investigation/insurance/insurance.component';
import { WorkshopCalendarComponent } from './pages/displays/feria/calendar/calendar.component';
import { EventCalendarComponent } from './pages/displays/events/calendar/calendar.component';
import { BusinessComponent } from './pages/displays/business/business.component';
import { BusinessDetailComponent } from './pages/displays/business/detail/business-detail.component';
import { BusinessCalendarComponent } from './pages/displays/business/calendar/calendar.component';
import { ProjectComponent } from './pages/investigation/projects/project.component';
import { ProjectDetailsComponent } from './pages/investigation/projects/details/details.component';
import { TrainingsResolver } from './pages/investigation/trainings/trainings.resolver';
import { ConsultancyResolver } from './pages/investigation/consultancy/consultancy.resolver';
import { NoticeResolver } from './pages/notices-detail/notices.resolver';
import { ProjectResolver } from './pages/investigation/projects/project.resolver';
import { StatisticResolver } from './pages/statistics-detail/statistics.resolver';
import { EventsResolver } from './pages/displays/events/events.resolver';
import { BusinessResolver } from './pages/displays/business/business.resolver';
import { FeriaResolver } from './pages/displays/feria/feria.resolver';
import { LocaleUsComponent } from './pages/contacts/locale-us/locale-us.component';
import { PqrsComponent } from './pages/contacts/pqrs/pqrs.component';
import { FaqsComponent } from './pages/contacts/faqs/faqs.component';
import { ProfileOfficialComponent } from './pages/info/profile-official/profile-official.component';

import { PagesModule } from './pages/pages.module';



const routes: Routes = [

    {
      path: '',
      loadChildren: './pages/pages.module#PagesModule'
    }


];

const config: ExtraOptions = {
  useHash: false,
};


@NgModule({
  imports: [
    RouterModule.forRoot( routes, config)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
