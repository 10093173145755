import { isNullOrUndefined } from 'util';
import { Component, OnDestroy } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, RouterModule, NavigationEnd, ActivatedRoute, ActivationEnd, ResolveEnd } from '@angular/router';

import { map, filter,mergeMap, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent 
{
  // ngOnDestroy(): void {
  //   this.tag$.unsubscribe();
  // }
  // tag$: Subject<boolean> = new Subject<boolean>();;
  // constructor(private router: Router, title: Title,private activatedRoute: ActivatedRoute,
  //    private meta: Meta) 
  // {
  //   this.router.events
  //   .pipe(takeUntil(this.tag$))
  //   .subscribe((event) => 
  //   {
  //     const navEndEvents$ = this.router.events 
  //     .pipe( filter(event => event instanceof NavigationEnd));

  //     navEndEvents$.subscribe((event: NavigationEnd) => 
  //     {
  //       gtag('config', 'UA-106538948-1', 
  //       {
  //         'page_path': event.urlAfterRedirects
  //       });
  //     });

  //     if (router.url != '/' && event instanceof ActivationEnd) 
  //     {
  //       var titulo = (event['snapshot'].data['title']) ? event['snapshot'].data['title'] : '';
  //       var description = (event['snapshot'].data['description']) ? event['snapshot'].data['description'] : '';
  //       var img = (event['snapshot'].data['img']) ? event['snapshot'].data['img'] : '';
  //       title.setTitle(titulo + ' - Cotelco');
  //       meta.updateTag({name: 'description', content: description});

  //       this.meta.addTag({ name: 'twitter:card', content: 'summary_large_image' });
  //       this.meta.addTag({ name: 'twitter:site', content: '@Cotelcolombia' });
  //       this.meta.addTag({ name: 'twitter:title', content: titulo });
  //       this.meta.addTag({ name: 'twitter:description', content: description });
  //       this.meta.addTag({ name: 'twitter:image', content: img });

  //       this.meta.updateTag({ name: 'og:title', content: titulo });
  //       this.meta.updateTag({ name: 'og:type', content: 'article' });
  //       this.meta.updateTag({ name: 'og:url', content: router.url });
  //       this.meta.updateTag({ name: 'og:description', content: description });
  //       this.meta.updateTag({ name: 'og:image', content: img });

  //       if (event['snapshot'].data['resolveData']) {
  //         let data = event['snapshot'].data['resolveData']
  //         let image;
  //         if (data.poster) {
  //           image = data.poster
  //         }else if(data.image){
  //           image = data.image
  //         }
  //         let name;
  //         if (data.name) {
  //           name = data.name
  //         }else{
  //           name = data.title
  //         }
  //         title.setTitle(name + ' - Cotelco');
  //         meta.updateTag({name: 'description', content: description.replace(/(<([^>]+)>)/ig,"")});
          

  //         this.meta.addTag({ name: 'twitter:title', content: name });
  //         this.meta.addTag({ name: 'twitter:description', content: data.description.replace(/(<([^>]+)>)/ig,"") });
  //         this.meta.addTag({ name: 'twitter:image', content: image });

  //         this.meta.updateTag({ name: 'og:title', content: name });
  //         this.meta.updateTag({ name: 'og:type', content: 'article' });
  //         this.meta.updateTag({ name: 'og:url', content: router.url });
  //         this.meta.updateTag({ name: 'og:description', content: data.description.replace(/(<([^>]+)>)/ig,"") });
  //         this.meta.updateTag({ name: 'og:image', content: image });
  //       }
  //       this.tag$.next(true);
  //     } 
  //     else
  //     {
  //       this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
  //       this.meta.updateTag({ name: 'twitter:site', content: '@Cotelcolombia' });
  //       this.meta.updateTag({ name: 'twitter:title', content: 'Cotelco - Asociación Hotelera y Turística de Colombia' });
  //       this.meta.updateTag({ name: 'twitter:description', content: 'La Asociación Hotelera y Turística de Colombia – COTELCO es un gremio federado que representa y apoya los intereses del sector hotelero y turístico colombiano fortaleciendo su competitividad y productividad mediante la prestación de servicios integrales a sus afiliados y del turismo en general.' });
  //       this.meta.updateTag({ name: 'twitter:image', content: '/assets/images/seal.png' });

  //       this.meta.updateTag({ name: 'og:title', content: 'Cotelco - Asociación Hotelera y Turística de Colombia' });
  //       this.meta.updateTag({ name: 'og:type', content: 'article' });
  //       this.meta.updateTag({ name: 'og:url', content: router.url });
  //       this.meta.updateTag({ name: 'og:description', content: 'La Asociación Hotelera y Turística de Colombia – COTELCO es un gremio federado que representa y apoya los intereses del sector hotelero y turístico colombiano fortaleciendo su competitividad y productividad mediante la prestación de servicios integrales a sus afiliados y del turismo en general.' });
  //       this.meta.updateTag({ name: 'og:image', content: 'noraml/assets/images/seal.png' });

  //     }

  //     if (event instanceof NavigationEnd && event.url != '/') 
  //     {
  //       this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
  //       this.meta.updateTag({ name: 'twitter:site', content: '@Cotelcolombia' });
  //       this.meta.updateTag({ name: 'twitter:title', content: 'Cotelco - Asociación Hotelera y Turística de Colombia' });
  //       this.meta.updateTag({ name: 'twitter:description', content: 'La Asociación Hotelera y Turística de Colombia – COTELCO es un gremio federado que representa y apoya los intereses del sector hotelero y turístico colombiano fortaleciendo su competitividad y productividad mediante la prestación de servicios integrales a sus afiliados y del turismo en general.' });
  //       this.meta.updateTag({ name: 'twitter:image', content: '/assets/images/seal.png' });

  //       this.meta.updateTag({ name: 'og:title', content: 'Cotelco - Asociación Hotelera y Turística de Colombia' });
  //       this.meta.updateTag({ name: 'og:type', content: 'article' });
  //       this.meta.updateTag({ name: 'og:url', content: router.url });
  //       this.meta.updateTag({ name: 'og:description', content: 'La Asociación Hotelera y Turística de Colombia – COTELCO es un gremio federado que representa y apoya los intereses del sector hotelero y turístico colombiano fortaleciendo su competitividad y productividad mediante la prestación de servicios integrales a sus afiliados y del turismo en general.' });
  //       this.meta.updateTag({ name: 'og:image', content: '/assets/images/seal.png' });
        
  //     }

  //   });
  // }
}
